.root{
    composes: page-container from global;
    padding-bottom: 150px;
}
.titlePage{
    font-size: 2.7rem;
    text-transform: uppercase;
    color: var(--theme-colors-nav);
    font-family: var(--theme-font-title);
    margin-top: 150px;
    text-align: right;
}
.titlePage > span{
    color: var(--theme-colors-yellow);
}
.numberCmd{
    font-size: 28px;
    text-transform: uppercase;
    color: #2C2E35;
    margin: 20px 0 50px;
}
.numberCmd > span{
    font-weight: 100;
}
.containerAlignRight{
    display: flex;
    justify-content: flex-end;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 10px;
    right: 10px;
}
.blockInfo{
    background: var(--theme-colors-main);
    color: white;
    box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 10%);
    border-radius: 0.5rem;
    padding: 1.5rem;
    width: 300px;
}
.blockInfo img{
    width: 30px;
    margin-right: 12px;
}
.blockInfo h4{
    font-size: 30px;
}
.blockInfo p{
    margin: 0;
    margin-bottom: 8px;
    width: 100%;
    font-size: 18px;
}
.blockInfo p:last-child{
    margin-bottom: 0;
}
.blockInfo p > span{
    color: lightgray;
    font-size: 14px;
    text-align: center;
    width: 100%;
    display: block;
}
.blockInfo > div:first-child{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 16px;
}
.blockInfo a{   
    color: white;
    text-decoration: none;
}

.title{
    /* font-family: var(--theme-font-title); */
    color: var(--theme-colors-nav);
    flex: 1;
    line-height: 1;
    text-transform: uppercase;
}

.collapse_heading{
    cursor: pointer;
    display: flex;
    align-items: center;
}

.collapse_heading_open{
    composes : collapse_heading;
    padding-bottom: 1rem;
    border-bottom: 1px var(--theme-colors-grey-border) solid;

}

.chevron{
    color: var(--theme-colors-yellow);
}

.collapseContainer{
    max-width: 500px;
}
.collapse_content{
    background-color: white;
    box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 10%);
    width: 100%;
    padding: 0.5rem 1rem;
    padding-left: 1.7rem;
    border-radius: 0.5rem;
    position: relative;
}