.buttonActive{
    transition: background-color ease-in-out .3s;
    background-color: var(--theme-colors-yellow);
}

.buttonDisabled{
    transition: background-color ease-in-out .3s;
    background-color: #dfdfdf;
    color: var(--theme-colors-darker-grey-text);    
}

button{
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

.buttonActive:hover, .buttonDisabled:hover {
    background-color: var(--theme-colors-darker-yellow);
}