.title{
    font-family: var(--theme-font-title);
    color: white;
    flex: 1;
    line-height: 1;
}

.heading{
    cursor: pointer;
    display: flex;
    align-items: center;
}

.heading_open{
    background: var(--theme-colors-nav);
    box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 10%);
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    position: relative;
}

.chevron{
    color: var(--theme-colors-nav);
}


/* .content{
    padding-top: 1rem;
} */

.data_list{
    padding-left: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.data_item{
    list-style-type: none;
    color: var(--theme-colors-grey-text);
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    padding: 10px 0;
}

.data_item strong{
    width: 300px;
    color: var(--theme-colors-nav);
}


.contact_title{
    font-size: 1rem;
    font-family: var(--theme-font-title);
    color: var(--theme-colors-main);
    margin-top: 0.5rem;
}

div[class="section"]{
    width: 100%;
}
div[class="section"]:first-child, div[class="section"]:nth-child(2), div[class="section"]:nth-child(6), div[class="section"]:nth-child(7){
    width: 49%;
}

span{
    color: var(--theme-colors-nav);
}

tr td{
    border-top: 2px solid white;
}