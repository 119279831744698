.root {
    composes: page-container from global;
}

.orders_container {
    margin-top: 2rem;
    width: 100%;
}

.orders_table {
    border-spacing: 0;
    width: 100%;
}

.orders_table_container {
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    overflow-x: scroll;
    width: 100%;
}

ul {
    list-style: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

ul li[class="active"] a {
    background-color: var(--theme-colors-yellow);
    color: var(--theme-colors-nav);
    cursor: initial;
}

ul li a {
    background: var(--theme-colors-nav);
    padding: 8px;
    color: var(--theme-colors-yellow);
    cursor: pointer;
    border-radius: 5px;
    margin: 0 4px;
    font-weight: bold;
}

ul li a:hover {
    opacity: 0.8;
}

ul li[class="active"] a:hover {
    opacity: 1;
}

.select{
    height: 25px;
    border-radius: 6px;
    border: 2px solid;
}

.blocPagination{
    display: flex;
    justify-content: space-between;
}

.containerPagination{
    display: flex;
    align-items: center;
    color: var(--theme-colors-nav);
    font-size: 1rem;
}

.pagination li:first-child{
    transform: rotate(270deg);
}

.pagination li:last-child{
    transform: rotate(90deg);
}

.pagination li:global(.disabled){
    display: none !important;
}

.selectPagination{
    display: flex;
    justify-content: flex-end;
    color: var(--theme-colors-nav);
    font-size: 1rem;
    align-items: center;
}

.selectPaginationText{
    margin-left: 10px;
}

.leftTitle, .leftTitleFilter{
    font-family: var(--theme-font-title);
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.inputsDates{
    display: flex;
    justify-content: space-between;
}
.inputContainer{
    display: flex;    
}

.inputDate, .inputDateBis{
    display: flex;
    align-items: center;
    position: relative;
}

.dateTitle, .searchTitle{
    background-color: var(--theme-colors-nav);
    color: white;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    width: 135px;
    text-align: center;
}

.inputTypeDate{
    border-color: #a3a3a3;
    border-radius: 5px;
    padding: 3px 10px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    left: 130px;
    position: absolute;
    box-shadow: 10px 10px 20px 0px rgb(163 163 163 / 80%);
    border-width: 1px;
    font-family: var(--theme-font);
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
    background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat; 
} */

.inputDateBis{
    margin-left: 160px;
}

.filter{
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.pictoFilter{
    width: 30px;
}

.leftTitleFilter{
    margin-top: 0;
    margin-right: 10px;
}

.backgroundContainer {
    background-color: black;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
}

.backgroundContainer:hover{
    cursor: pointer;
}

.heading_open {
    background: var(--theme-colors-nav);
    box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 10%);
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    position: relative;
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.title{
    margin-right: 10px;
}

.popup{
    position: fixed;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    max-width: 750px;
}

.card{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.show{
    text-transform: uppercase;
    font-weight: 700;  
    text-align: flex-end;
    margin-top: 0;
}

.checkbox{
    display: flex;
    align-items: center;
}

.containerCheckbox{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.containerButton{
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.buttonBlue, .buttonYellow{
    background-color: var(--theme-colors-nav);
    border: none;
    color: white;
    font-weight: 700;
    padding: 5px 20px;
    border-radius: 5px;
    margin-right: 10px;
    text-transform: uppercase;
}

.buttonBlue{
    height: 30px;
}

.buttonYellow{
    background-color: var(--theme-colors-yellow);
    color: var(--theme-colors-navs);
    height: 30px;
}

.buttonYellow:hover, .buttonBlue:hover, .sÒearchTitle:hover {
    opacity: 0.8;
}

.linkButtonBlue, .linkButtonBlue:hover, .linkSearchTitle, .linkSearchTitle:hover{
    color: white;
}

.linkButtonYellow,
.linkButtonYellow:hover {
    color: var(--theme-colors-nav);
}

/* .checkboxStyle {
    position: absolute;
    top: 19px;
    left: 19px;
    height: 20px;
    width: 20px;
    background-color: #eee;
}

/* input~.checkboxStyle { */
    /* background-color: #ccc;
} */
/* 
input:checked~.checkboxStyle {
    background-color: #2196F3;
} */

.checkboxStyle:after {
    content: "";
    position: absolute;
    display: none;
    left: 22px;
    top: 15px;
    width: 7px;
    height: 14px;
    border: 1px solid var(--theme-colors-yellow);
    border-width: 0 5px 5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

input:checked~.checkboxStyle:after {
    display: block;
}

input[type="checkbox"]{
    width: 20px;
    height: 20px;
}

.container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmarks {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: white;
    border: solid 2px var(--theme-colors-nav);
    border-radius: 5px;
}

.container input:checked~.checkmarks {
    background-color: white;
    border: solid 2px var(--theme-colors-nav);
    border-radius: 5px;
}

.checkmarks:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked~.checkmarks:after {
    display: block;
}

.container .checkmarks:after {
    left: 2px;
    top: -8px;
    width: 10px;
    height: 20px;
    border: solid var(--theme-colors-yellow);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.pictoFilter:hover{
    cursor: pointer;
    opacity: 0.8;
}

a:hover{
    cursor: pointer;
}

.heading_open, .card{
    width: 100%;
}

.formSearch{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.formSearch form{
    display: flex;
}

.formSearch form button{
    position: absolute;
    right: 225px;
    height: 26px;
}

.formSearch form input{
    border-color: #a3a3a3;
    background-color: white;
    border-radius: 5px;
    padding: 3px 10px;
    box-shadow: 10px 10px 20px 0px rgb(163 163 163 / 80%);
    border-width: 1px;
    font-family: var(--theme-font);
    height: 26px;
}

.filterContainer{
    float: right;
}

.buttonContainer{
    display: flex;
    justify-content: flex-end;
}

.resetFilter {
    background-color: var(--theme-colors-yellow);
    transition: background-color ease-in-out .3s;
}

.resetFilter:hover {
    background-color: var(--theme-colors-darker-yellow);
}
