.title{
    composes : title from global;
}

.footer{
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: flex-end;
    gap: 1rem;
}


.root td{
    padding: 0 0.5rem 0 1rem;
    border-bottom: 2px solid var(--theme-colors-grey-border);
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}
.disabled{
    pointer-events: none;
}

.actions a{
    display: inline-block;
    margin-right: 1rem;
}

.actions a:last-of-type{
    margin-right: 0;
}

.actions,.date{
    white-space: nowrap;
}

.root:nth-child(odd){
    background: #fbfbfb;
}

.pictoDetails{
    width: 30px;
    margin-top: 10px;
    margin-bottom: -10px;
}

.actionsNull img{
    opacity: 0.5;
    
}

.lien{
    display: contents;
}

.lien tr td{
    transition: background-color ease-in-out .2s;
}

.lien:hover{
    color: var(--theme-colors-navs);
}

.lien:hover tr td{
    background-color: var(--theme-colors-grey-border);
}

tr td{
    font-size: 0.9rem;
    border-radius: 0px !important;
    border-top: 2px solid var(--theme-colors-grey-border) !important;
    border-bottom: none !important;
}

.info{
    fill: var(--theme-colors-yellow);
    stroke: white;
    margin-top: 10px;
    margin-bottom: -10px;
    transition: fill ease-in-out .3s;
}

.info:hover{
    fill: var(--theme-colors-darker-yellow);
}