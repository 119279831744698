.backgroundContainer {
    background-color: black;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
}

.popup{
    position: fixed;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.heading_open {
    background: var(--theme-colors-nav);
    box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 10%);
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    position: relative;
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.card{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.containerButton {
    width: 100;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.button, .buttonYellow{
    background-color: var(--theme-colors-nav);
    border: none;
    color: white;
    font-weight: 700;
    padding: 5px 20px;
    border-radius: 5px;
    margin-right: 10px;
    text-transform: uppercase;
}


.buttonYellow{
    background-color: var(--theme-colors-yellow);
    color: var(--theme-colors-navs);
}

.buttonYellow:disabled {
    opacity: .5;
}

.pageInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
}

.importTable {
    border-collapse: collapse;
    width: 100%;
    text-transform: none;
}

.importTable tr {
    background-color: white!important;
    margin-top: 1px solid var(--theme-colors-darker-grey-text);
}

.importTable td {
    text-transform: none;
}

